<template>
    <v-container fluid fill-height style="padding:0px">
        <transition name="fade-transition">
            <template v-if="!fetching && Lessons">
                <v-container fluid fill-height v-bind:style="{'background-color': Lessons.room_color}" :class="background_class" >
                    
                    <!-- Hide container for teacher  -->
                    <!-- <v-row v-if="User.role == 'teacher'" style="align-self: start">
                        <h2 class="large-text text-center custom-font">LESSONS</h2>
                        <h2 class="medium-text custom-font">Unit {{Lessons.room_name}}</h2>
                        <ul v-if="Lessons.items">
                            <template v-for="item in Lessons.items"> -->
                                <!-- eslint-disable-next-line -->
                                <!-- <li :key="Lessons.id" @click="goToLesson(item.id, item.name)" class="medium-text custom-font">{{item.name}}</li>
                            </template> 
                        </ul>
                    </v-row> -->
                    <v-row v-if="User.role == 'student'" style="align-self: start" class="student-view">
                        <v-col cols="12" sm="2">
                            <v-img :src='require(`@/assets/${Lessons.left_room_icon}`)'></v-img>
                        </v-col>
                        <v-col cols="12" sm="8">
                            <h2 class="large-text text-center local-custom-font" style="padding:15px 0px">{{Lessons.room_name}}</h2>
                            <v-expansion-panels dark>
                                <v-expansion-panel
                                v-for="item in Lessons.Lessons"
                                    :key="item.id"
                                >
                                <v-expansion-panel-header @click="getActivities(item.id)">
                                    <h1 class="local-custom-font">{{item.title}}</h1>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <transition name="fade-transition">
                                        <ul v-if="Activities">
                                            <li :class="[item.class, item.LearningObjectivesVisited]"><p @click="$router.push({name: 'Learning Objectives', params: {id: item.id}})" class="activity-name">Learning Objective</p></li>
                                            <li v-for="item1 in Activities" :key="item1.id" :class="[item1.class, item1.visited]">
                                                <p @click="goToActivity(item1.id, item1.name, item.id)" class="activity-name">{{item1.title}}</p>
                                            </li>
                                        </ul>
                                    </transition>
                                    <transition name="fade-transition">
                                        <div class="within-div-loader" v-if="!Activities">
                                            <v-progress-circular
                                                indeterminate
                                                color="#000000"
                                                class="progressLoader">
                                            </v-progress-circular>
                                        </div>
                                    </transition>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <BackBtn @close="goBack()"></BackBtn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </transition>
        <transition name="fade-transition">
            <div class="full-screen-loader" v-if="fetching">
                <v-progress-circular
                    indeterminate
                    color="#000000"
                    class="progressLoader">
                </v-progress-circular>
            </div>
        </transition>
    </v-container>
</template>
<script>
import BackBtn from '@/components/BackBtn.vue';
//import LearningObjectives from '@/components/LearningObjectives.vue';
export default {
  components: { BackBtn },
    name:'Lessons',
    //components: { LearningObjectives },
    data(){
        return{
            background_class: ''
        }
    },
    mounted(){
        if(this.$store.state.user){
            this.getLessons();
            if(this.$store.state.user.role == 'student'){
                this.$store.state.fetching = true;
                this.$store.dispatch('getLessons', this.$route.params.id)
                this.background_class = ''
            }
            else{
                this.background_class = 'standard-bg';
            }
        }
    },
    methods:{
        getLessons(){
            this.fetching = true;
            this.$store.dispatch('getLessons', this.$route.params.id);
        },
        getActivities(LessonID){
            this.fetching = true;
            this.$store.dispatch('getActivities', LessonID);
        },
        goToLesson(Lesson_id, Lesson_name){
            this.$router.push({ name: 'Lesson Detail', params: { id: Lesson_id, name: Lesson_name, slug: Lesson_name.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-') } })
        },
        goToActivity(Activity_id, Activity_name, Lesson_id){
            this.$router.push({name: 'Activity', params: {id: Activity_id, name: Activity_name, lessonID: Lesson_id}})
        },
        /* closeLearningObjective(){
            this.showLearningObjectives = false;
            this.objectiveSkip = false;
            this.getLessons();
        }, */
        goBack(){
            this.$router.push({path: '/rooms'})
        }
    },
    computed:{
        Lessons(){
            return this.$store.state.Lessons;
        },
        Activities(){
            return this.$store.state.Activities;
        },
        fetching:{
            get(){
                return this.$store.state.fetching
            },
            set(value){
                this.$store.state.fetching = value;
            }
        },
        User(){
            return this.$store.state.user;
        },
    }
}
</script>
<style scoped>
    .student-view .local-custom-font{
        font-family: 'Lomo', sans-serif;
        color: #fff;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    }
    .activity-name{
        text-shadow: 5px 5px 5px rgb(0, 0, 0);
    }
    .activity-name:hover{
        text-shadow: 5px 5px 5px rgb(0,0,0);
        /*color: #6FC41E; */
    }
    .student-view .v-expansion-panel, .v-expansion-panel::before{
        background-color: transparent !important;
        box-shadow: none !important;
    }
    .student-view .v-image >>> .v-image__image {
        background-size: 115% 125%;
        background-position: top !important;
    }
    .v-expansion-panel:not(:first-child):after{
        border: none !important;
    }
    .v-expansion-panel >>> .v-expansion-panel-content .v-expansion-panel-content__wrap{
        padding-bottom: 0px;
    }
    li{
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s;
    }
    li.disabled{
        cursor: not-allowed;
    }
    li.disabled p{
        pointer-events: none;
    }
    li:hover, li.visited{
        color: #6FC41E;
        text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
    }
</style>