<template>
<div>
    <!-- <v-hover>
        <v-img class="back-icon"
            slot-scope="{ hover }"
            v-if="hover"
            :src="backIconHover"
            @click="$router.go(-1)"  
        >
        </v-img>
        <v-img class="back-icon"
            v-else
            :src="backIcon"
            @click="$router.go(-1)"
        >
        </v-img>
    </v-hover> -->
    <v-icon x-large class="back-icon" v-on:click="$emit('close')">
        west
    </v-icon>
    </div>

</template>

<script>

// import backIconUnclicked from '@/assets/back-button-unclicked.png';
// import backIconClicked from '@/assets/back-button-clicked.png';

export default {
    name: 'BackBtn',
    data() {
        return {
            // backIcon: backIconUnclicked,
            // backIconHover: backIconClicked,
        };
    },  
}
</script>

<style scoped>
/* .back-icon{
    height: 45px;
    width: 60px;
} */

.back-icon{
    font-size: 4rem  !important;
    background: transparent !important;
    color: white !important;
    -webkit-text-stroke-width: 1px;  
    -webkit-text-stroke-color: grey; 
    text-shadow: 3px 5px 4px rgba(0,0,0,0.5);
    position: fixed;
    left: 30px;
    bottom: 30px;
}

.back-icon:hover{
    cursor: pointer;
    color: #00FF00 !important;
}

</style>